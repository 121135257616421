<script>
import DashboardReportService from '@/services/DashboardReportService'

export default {
  name: 'Report',
  props: {
    filters: {
      type: Object,
      default () { return {} }
    },
    index: {
      type: Number,
      default () { return 0 }
    },
    report: {
      type: Object,
      default () { return {} }
    }
  },

  data () {
    return {
      loading: false,
      data: null,
      settings: [1, 2, 3],
      colors: [
        { backgroundColor: '#BE191E', borderColor: '#A0171B', hoverBackgroundColor: '#FFCED0' },
        { backgroundColor: '#FF6C00', borderColor: '#D35900', hoverBackgroundColor: '#FFCEAA' },
        { backgroundColor: '#FFBA00', borderColor: '#C48F00', hoverBackgroundColor: '#FFEBB4' },
        { backgroundColor: '#28A745', borderColor: '#00771B', hoverBackgroundColor: '#C1E8C0' },
        { backgroundColor: '#15A2B8', borderColor: '#107989', hoverBackgroundColor: '#C9F8FF' },
        { backgroundColor: '#985E00', borderColor: '#6A4200', hoverBackgroundColor: '#EACFA3' },
        { backgroundColor: '#BE19A5', borderColor: '#A0176B', hoverBackgroundColor: '#FFCEF9' },
        { backgroundColor: '#5A15B8', borderColor: '#3A008B', hoverBackgroundColor: '#D6B9FF' },
        { backgroundColor: '#155AB8', borderColor: '#003379', hoverBackgroundColor: '#98C3FF' },
        { backgroundColor: '#15B892', borderColor: '#008B6B', hoverBackgroundColor: '#A9EADB' },
        { backgroundColor: '#7BA728', borderColor: '#4E7700', hoverBackgroundColor: '#D6E8C0' }
      ]
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      this.loading = true
      DashboardReportService.initialize(this.report.report)
      DashboardReportService.get(this.report.report, this.filters).then((response) => {
        this.data = response
      }).catch((error) => {
        console.dir(error.response)
        this.$aimNotify.error(error.response)
      }).finally(() => {
        this.loading = false
      })
    },

    async fetch () {
      this.loading = true
      DashboardReportService.fetch(this.report.report, this.filters).then((response) => {
        this.data = response
      }).catch((error) => {
        this.$aimNotify.error(error.response)
      }).finally(() => {
        this.loading = false
      })
    },

    getBgColor (idx) {
      if (idx > 10) {
        idx = 10
      }
      return (this.colors) ? this.colors[idx].backgroundColor : 'transparent'
    },

    getColorArray (type) {
      const arr = []

      this.colors.forEach(e => {
        if (e[type]) {
          arr.push(e[type])
        }
      })

      return arr
    },

    getShortData (data) {
      let shortlabels = data.labels
      let shortData = data.data
      if (data.data.length > 10) {
        shortData = data.data.slice(0, 10)
        const othersum = data.data.slice(10, data.data.length)
        shortData.push(Math.round(othersum.reduce((prev, cur) => (+prev) + (+cur))))
        shortlabels = data.labels.slice(0, 10)
        shortlabels.push('Other')
      }

      return { labels: shortlabels, data: shortData }
    }
  },

  watch: {
    filters: {
      handler: function (n, o) {
        if (n) {
          this.fetch()
        }
      },
      deep: true
    }
  }
}
</script>
