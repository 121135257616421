<template>
  <div>
    <b-row>
      <b-col cols="6">
        Widget
      </b-col>
      <b-col cols="2" class="text-center">
        Graph
      </b-col>
      <b-col cols="2" class="text-center">
        Table
      </b-col>
    </b-row>
    <hr style="border-top: 1px solid white;">
    <b-row v-for="w in widgets" :key="w.id">
      <b-col cols="6">
        {{ w.name }}
      </b-col>
      <b-col cols="2" class="text-center">
        <b-checkbox
          v-if="checkForType(w, 'graph')"
          :value="{ report: w.report, display: 'graph' }"
          v-model="widgetsToAdd"
        ></b-checkbox>
      </b-col>
      <b-col cols="2" class="text-center">
        <b-checkbox
          v-if="checkForType(w, 'table')"
          :value="{ report: w.report, display: 'table' }"
          v-model="widgetsToAdd"
        ></b-checkbox>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    widgets: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      widgetsToAdd: []
    }
  },
  methods: {
    checkForType (widget, type) {
      return widget.display.includes(type)
    }
  },
  watch: {
    widgetsToAdd: function (n, o) {
      if (n !== o) {
        this.$emit('update', n)
      }
    }
  }
}
</script>
