<template>
  <ReportCard
    :report="report"
    :index="index"
    :filters="filters"
    :loading="loading"
    @dashboardReportSetDisplay="(data) => $emit('dashboardReportSetDisplay', data)"
    @refresh="$emit('refresh')"
  >
    <div
      class="chart-container"
      v-if="this.data && this.report.display === 'graph'"
    >
      <LineChart
        :data="formatChartData(data)"
        :options="options"
      ></LineChart>
    </div>
    <div v-else>
      No data to display
    </div>
  </ReportCard>
</template>
<script>
import Report from '@/components/dashboard/Report'
import ReportCard from '@/components/dashboard/ReportCard'
import LineChart from '@/components/dashboard/reports/charts/Line'

export default {
  name: 'RevenueComparison',
  components: { ReportCard, LineChart },
  extends: Report,

  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false,
              callback: function (label, index, labels) {
                return '$' + label / 1000 + 'k'
              }
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              // const label = data.labels[tooltipItem.index]
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              return this.$convertToCurrency(value)
            }
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: 'transparent',
            borderRadius: 4,
            color: 'black',
            font: {
              weight: 'bold'
            },
            formatter: (val) => {
              return this.$convertToCurrency(val)
            },
            padding: 6,
            display: 'auto',
            clamp: false,
            anchor: 'start',
            align: (val) => {
              if (val.dataIndex === 0) {
                return '315'
              } else if (val.dataIndex === (val.dataset.data.length - 1)) {
                return '225'
              }
              return 'top'
            },
            offset: 10
          }
        }
      }
    }
  },

  methods: {
    formatChartData (data) {
      data.data[0].labels.forEach((e, idx) => {
        data.data[0].labels[idx] = e.slice(-2)
      })

      const chartData = {
        labels: data.data[0].labels,
        datasets: [
          {
            label: data.data[0].label,
            data: data.data[0].data,
            borderWidth: 1,
            backgroundColor: this.colors[0].backgroundColor + '33',
            borderColor: this.colors[0].borderColor,
            pointBorderColor: this.colors[0].borderColor
          }
        ]
      }
      if (data.data.length > 1) {
        data.data[1].labels.forEach((e, idx) => {
          data.data[1].labels[idx] = e.slice(-2)
        })
        // we have comparison data
        chartData.datasets.push({
          label: data.data[1].label,
          data: data.data[1].data,
          borderWidth: 1,
          backgroundColor: this.colors[1].backgroundColor + '33',
          borderColor: this.colors[1].borderColor,
          pointBorderColor: this.colors[1].borderColor
        })
      }
      return chartData
    }
  }
}
</script>
