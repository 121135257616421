<style scoped>
.leftBarHover:hover {
  border-left: 3px solid blue;
}
.rightBarHover:hover {
  border-right: 3px solid blue;
}
.delete {
  position: absolute;
  height: 80%;
  width: 94%;
}
.hidden-box {
  position: absolute;
  height: 80%;
  width: 50%;
}

.hidden-box.left {
  left: 0;
}

.hidden-box.right {
  right: 0;
}

@media all and (min-width: 992px) {
  .delete {
    width: 82%;
  }
}
</style>
<template>
  <div>
    <b-row>
      <b-col
        v-for="(widget, index) in layout"
        :key="index"
        :class="getWidgetClass(getWidgetWidth(widget))"
      >
        <div
          :style="getLocation(index, 'left')"
          class="leftBarHover hidden-box left"
          v-if="index != location && location + 1 != index"
          @click="moveTo(index, -1)"
        ></div>
        <div
          :style="getLocation(index, 'right')"
          class="rightBarHover hidden-box right"
          v-if="index != location && location - 1 != index"
          @click="moveTo(index, 1)"
        ></div>
        <div class="delete" v-if="index == location" @click="remove()"></div>
        <h3
          class="text-center mini-dash-col"
          :style="
            location == index
              ? 'color: red; border-color: red;'
              : 'color: black'
          "
        >
          <b-icon-trash-fill v-if="location == index"></b-icon-trash-fill>
          <span v-else>{{ index + 1 }}</span>
        </h3>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { bus } from '@/main'
import DashboardLayoutService from '@/services/DashboardLayoutService'

export default {
  props: {
    item: {
      type: Number,
      default: 0
    },
    filters: {
      type: Object,
      default () { return {} }
    }
  },

  data () {
    return {
      widgets: [],
      layout: [],
      location: -1
    }
  },

  mounted () {
    bus.$on('widgets', (items) => {
      this.widgets = items
    })
    bus.$emit('getWidgets')
    DashboardLayoutService.getLayout(this.filters).then(
      (response) => {
        const layout = response.data.info
        this.layout = layout
        this.location = this.item
      }
    )
  },

  methods: {
    moveTo (index, side) {
      // create temp array to hold the new values that we
      const tmp = []
      // this var will be used for setting the location in the array later, stored in this.location
      let tmpLoc = -1

      // get the value of the widget we are moving
      const wid = this.layout[this.location]

      // loop through the array of our current layout
      for (let i = 0; i < this.layout.length; i++) {
        // this if statement lets us skip over the item that we are moving since we don't want it to be placed in the layout
        if (i !== this.location) {
          /*
            if the current loop iteration is the one that we clicked on
            and we are placing it to the LEFT of that index (they clicked on the LEFT)
            then place in our widget that we are moving and set its location
          */
          if (i === index && side < 0) {
            tmp.push(wid)
            tmpLoc = index
          }

          // add the item from the current layout to this temp array
          tmp.push(this.layout[i])
          /*
            if the current loop iteration is the one that we clicked on
            and we are placing it to the RIGHT of that index (they clicked on the RIGHT)
            then place in our widget that we are moving and set its location
          */
          if (i === index && side > 0) {
            tmp.push(wid)
            tmpLoc = (i + 1)
          }
        }
      }

      // set our values to that of the temporary values that we created
      this.layout = tmp
      this.location = (index > this.location) ? tmpLoc - 1 : tmpLoc

      // refresh the screen so the user can see what it will look like
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },

    remove () {
      this.$bvModal.msgBoxConfirm('Are you sure you want to remove this widget?')
        .then((val) => {
          if (val) {
            this.layout.splice(this.location, 1)
            this.location = -1
            this.$emit('deleted', this.layout)
          }
        })
    },

    getWidgetDefaults (widget) {
      let info = null
      this.widgets.some((w) => {
        if (widget === w.report) {
          info = w
          return true
        }
        return false
      })
      return info
    },

    getWidgetWidth (widget) {
      if (!widget) {
        console.log('Widget is null or undefined in getWidgetWidth')
        return 1
      }
      if (widget.width) {
        return widget.width
      } else {
        const defaults = this.getWidgetDefaults(widget.report)
        // defaults ? (defaults.width ? defaults.width : 1) : 1
        return defaults.width
      }
    },

    getWidgetClass (width) {
      if (+width === 3) {
        return 'col-12'
      } else if (+width === 2) {
        return 'col-lg-8 col-12'
      } else {
        return 'col-lg-4 col-12'
      }
    },

    getLocation (index, pos = null) {
      // create a var to count the total width of the items that lead up to an index
      let total = 0
      // using this var to save each iterations total % 3
      let lastMod = -1

      // loop from 0 to index provided
      for (let i = 0; i < index; i++) {
        // get the width of the layout item at position "i"
        const wid = this.getWidgetWidth(this.layout[i])
        // add that width to our total
        total += wid
        /*
          check if the current % 3 last one were remainder of 0.
          we do this because if we get 2 items in a row that are the same mod
          then that means it started a new line so I set the total to 3 so it takes it
          as a new line
        */
        if (total % 3 === 0 && lastMod === 0) {
          total = 3
        }

        // set our mod ammount
        lastMod = total % 3

        // if our mod is 0 then we are at the end of a line and need to reset the total
        if (lastMod === 0 || wid === 3) {
          total = 0
        }

        if (total > 3) {
          total = 2
        }
      }

      // get the width of the widget at position "index" of layout
      const len = this.getWidgetWidth(this.layout[index])

      // "loc" is the var for deciding if it is at the beginning, middle, or end of the row
      // start = 0, middle = 1, end = 2, full row = 3
      let loc = 1
      // if our total % 3 is 0 or our total + our current width is greater than 3 then this is a start
      if (total % 3 === 0 || (total + len) > 3) {
        loc = 0
      } else if ((total + len) % 3 === 0) { // if our total + our width % 3 is 0 then its an end of a row
        loc = 2
      }

      if (len === 3) { // if our width is 3 its automatically a full width no matter what
        loc = 3
      }

      if (!pos) { // if no position is set (left or right), we just return that number
        return loc
        // return loc + ' ' + total + ' ' + len
      }

      let style = {}

      // just setting the styling for the left side so the bar is in the correct place on hover
      if (pos === 'left') {
        style = { left: 0 }
        if (loc === 0 || loc === 3) {
          style.left = '8px'
        } else {
          style.left = '-2px'
        }
      }

      // just setting the styling for the right side so the bar is in the correct place on hover
      if (pos === 'right') {
        style = { right: 0 }
        if (loc === 2 || loc === 3) {
          style.right = '8px'
        } else {
          style.right = '-1px'
        }
      }

      // this line is for testing
      // loc = '' + ((total + len) % 3)

      // return the styles
      return style
    }
  },

  watch: {
    layout: function (n, o) {
      if (n !== o) {
        if (n.length === o.length) {
          this.$emit('layoutChange', n)
        }
      }
    }
  }
}
</script>
