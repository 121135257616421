<template>
  <ReportCard
    :report="report"
    :index="index"
    :filters="filters"
    :loading="loading"
    @dashboardReportSetDisplay="(data) => $emit('dashboardReportSetDisplay', data)"
    @refresh="$emit('refresh')"
  >
    <div
      class="chart-container"
      v-if="this.data && this.report.display === 'graph'"
    >
      <BarChart
        :data="formatChartData(data)"
        :options="options"
      ></BarChart>
    </div>
    <div
      v-else-if="this.data && this.report.display === 'table'"
      class="table-responsive"
    >
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="(l, idx) in data.data[0].labels"
              :key="idx"
            >
              {{ l }}
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(d, didx) in data.data"
          :key="didx"
        >
          <tr>
            <td>{{ d.label }}</td>
            <td
              v-for="(v, idx) in d.data"
              :key="idx"
            >
              {{ v | currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      No data to display
    </div>
  </ReportCard>
</template>
<script>
import Report from '@/components/dashboard/Report'
import ReportCard from '@/components/dashboard/ReportCard'
import BarChart from '@/components/dashboard/reports/charts/Bar'

export default {
  name: 'TotalExpense',
  components: { ReportCard, BarChart },
  extends: Report,

  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                return '$' + label / 1000 + 'k'
              }
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            backgroundColor: 'transparent',
            borderRadius: 4,
            color: 'black',
            font: {
              weight: 'bold'
            },
            formatter: (val) => {
              return this.$convertToCurrency(val)
            },
            padding: 6,
            display: 'auto',
            clamp: false,
            anchor: 'start',
            align: 'end',
            offset: 10,
            clip: true
          }
        }
      }
    }
  },

  methods: {
    formatChartData (data) {
      const chartData = {
        labels: data.data[0].labels,
        datasets: [
          {
            label: data.data[0].label,
            data: data.data[0].data,
            borderWidth: 1,
            backgroundColor: [
              this.colors[0].hoverBackgroundColor,
              this.colors[0].hoverBackgroundColor,
              this.colors[0].hoverBackgroundColor
            ],
            borderColor: [
              this.colors[0].borderColor,
              this.colors[0].borderColor,
              this.colors[0].borderColor
            ],
            pointBorderColor: '#2554FF'
          }
        ]
      }
      if (data.data.length > 1) {
        // we have comparison data
        chartData.datasets.push({
          label: data.data[1].label,
          data: data.data[1].data,
          borderWidth: 1,
          backgroundColor: [
            this.colors[1].hoverBackgroundColor,
            this.colors[1].hoverBackgroundColor,
            this.colors[1].hoverBackgroundColor
          ],
          borderColor: [
            this.colors[1].borderColor,
            this.colors[1].borderColor,
            this.colors[1].borderColor
          ],
          pointBorderColor: '#2554FF'
        })
      }
      return chartData
    }
  }
}
</script>
