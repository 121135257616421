<template>
  <ReportCard
    :report="report"
    :index="index"
    :filters="filters"
    :loading="loading"
    @dashboardReportSetDisplay="(data) => $emit('dashboardReportSetDisplay', data)"
    @refresh="$emit('refresh')"
  >
    <Box :data="data"></Box>
  </ReportCard>
</template>
<script>
import Report from '@/components/dashboard/Report'
import ReportCard from '@/components/dashboard/ReportCard'
import Box from '@/components/dashboard/reports/charts/Box'

export default {
  name: 'GrossSalesKPM',
  components: { ReportCard, Box },
  extends: Report,
  props: {
    filters: {
      type: Object,
      default: null
    }
  }
}
</script>
