<template>
  <ReportCard
    :report="report"
    :index="index"
    :filters="filters"
    :loading="loading"
    @dashboardReportSetDisplay="(data) => $emit('dashboardReportSetDisplay', data)"
    @refresh="$emit('refresh')"
  >
    <div
      class="chart-container"
      v-if="this.data && this.report.display === 'graph'"
    >
      <DonutChart
        :data="formatChartData(data)"
        :options="options"
      ></DonutChart>
    </div>
    <div
      v-else-if="this.data && this.report.display === 'table'"
      class="table-responsive h-100"
      :data="formatChartData(data)"
    >
      <div
        class="small h-100"
        style="overflow: auto;"
      >
        <table class="table table-striped text-left table-condensed table-sm">
          <thead>
            <tr>
              <th></th>
              <th>Vendor</th>
              <th class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(v, idx) in data.data[0].data"
              :key="idx"
            >
              <td :style="'background-color: ' + getBgColor(idx)"><span>&nbsp;&nbsp;</span></td>
              <td>{{ data.data[0].labels[idx] }}</td>
              <td class="text-right">
                <span v-if="filters && filters.display === 'percent'">{{ v }}%</span>
                <span v-else>{{ v.toString().substring(1) | currency }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      No data to display
    </div>
  </ReportCard>
</template>
<script>
import Report from '@/components/dashboard/Report'
import ReportCard from '@/components/dashboard/ReportCard'
import DonutChart from '@/components/dashboard/reports/charts/Donut'

export default {
  name: 'LeadGeneration',
  components: { ReportCard, DonutChart },
  extends: Report,
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      maxheight: 316,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        },
        plugins: {
          title: {
            display: true,
            text: 'Chart.js Donut Chart'
          },
          datalabels: {
            backgroundColor: '#ddd',
            borderRadius: 4,
            color: 'black',
            font: {
              weight: 'bold'
            },
            formatter: (val, ctx) => {
              return ctx.chart.data.labels[ctx.dataIndex]
            },
            padding: 6,
            display: 'auto',
            clamp: true
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.labels[tooltipItem.index]
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              if (this.filters && this.filters.display === 'percent') {
                return label + ': ' + value + '%'
              } else {
                return label + ': ' + this.$convertToCurrency(value.toString().substring(1))
              }
            }
          }
        }
      }
    }
  },

  methods: {
    formatChartData (data) {
      const short = this.getShortData(data.data[0])
      const chartData = {
        labels: short.labels,
        datasets: [
          {
            label: 'Lead Generation',
            data: short.data,
            backgroundColor: this.getColorArray('backgroundColor'),
            hoverBackgroundColor: this.getColorArray('hoverBackgroundColor'),
            borderColor: this.getColorArray('borderColor')
          }
        ]
      }
      return chartData
    }
  }
}
</script>
