<template>
  <div class="h-100">
    <div v-if="data && data.data" style="color: black;" class="h-100">
      <b-row align-v="center" class="h-100">
        <b-col cols="12">
          <span class="h1">{{ data.data.label }}</span>
          <div
            v-if="data.data.value && data.data.value.length > 0"
          >
            <b-row>
              <b-col cols="12">
                <span v-if="data.data.value.length > 1">{{ data.data.value[0].label }}</span>
                <div class="h1">
                  {{ data.data.value[0].value }}
                </div>
                <div v-if="data.data.value[1]">
                  <span>{{ data.data.value[1].label }}</span>
                  <div class="h1">
                    {{ data.data.value[1].value }}
                  </div>
                </div>
                <div v-if="data.data.value[2]">
                  <!-- <span>{{ data.data.value[2].label }}</span> -->
                  <div class="h1" :style="'color: ' + getColor(+data.data.value[2].value)">
                    {{ data.data.value[2].value | showsign}}%
                    <b-icon icon="arrow-right" :style="'transform: rotate(' + getAngle(data.data.value[2].value) + 'deg)'"></b-icon>
                  </div>
                </div>

              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else style="color: black;">
      No data to display
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getAngle (val) {
      let res = 0

      res = (val / 100) * 90 * -1

      if (res > 90) {
        res = 90
      }

      if (res < -90) {
        res = -90
      }

      return res
    },

    getColor (val) {
      let color = 'black'

      if (val < 0) {
        color = this.reverse ? 'green' : 'red'
      } else if (val > 0) {
        color = this.reverse ? 'red' : 'green'
      }

      return color
    }
  },

  filters: {
    showsign (val) {
      let res = val + ''
      if (res > 0) {
        res = '+' + res
      }
      return res
    }
  }
}
</script>
