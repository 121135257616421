<template>
  <b-card
    v-if="report"
    class="p-0 h-100 report"
  >
    <template #header>
      <b-row
        class="justify-content-between m-0 p-0"
        no-gutters
        align-v="center"
      >
        <b-col
          class="text-left"
          cols="1"
        >
          <span
            @click.prevent="toggleDisplay"
            title="Toggle View"
            style="cursor: pointer;"
            class="type"
            v-if="report.defaults && report.defaults.display && report.defaults.display.length > 1"
          >
            <b-icon :icon="report.display === 'graph' ? 'graph-up' : 'table'"></b-icon>
          </span>
        </b-col>
        <b-col>
          <b-card-title class="m-0">
            {{ report.defaults.name ? report.defaults.name : '' }}
            <div style="transform: scale(0.8)" class="d-inline-block" v-if="report.defaults && report.defaults.details">
              <b-icon icon="info-circle-fill" v-b-popover.hover.click="report.defaults.details"></b-icon>
            </div>
          </b-card-title>
        </b-col>
        <b-col
          class="text-right"
          cols="1"
        >
          <b-icon-arrows-move class="move" v-b-modal="'moveModal' + index"></b-icon-arrows-move>
        </b-col>
      </b-row>
    </template>

    <!-- @ok="handleSave" -->

    <b-modal
      :id="'moveModal' + index"
      :title="'Move ' + report.defaults.name"
      no-close-on-backdrop
      no-close-on-esc
      @ok="handleSave"
      @show="tempLayout = []"
      @hidden="tempLayout = []"

      ok-title="Save"
    >
      <move :item="index" :filters="filters" @layoutChange="saveTempLayout($event, 0)" @deleted="saveTempLayout($event, -1)"></move>
    </b-modal>

    <b-overlay
      :show="loading"
      :id="'report_' + +index"
      rounded="sm"
      class="h-100"
    >
      <slot v-show="!loading"></slot>
    </b-overlay>
  </b-card>
</template>
<script>
import Move from '@/components/dashboard/Move'
import DashboardLayoutService from '@/services/DashboardLayoutService'

export default {
  name: 'ReportCard',
  components: {
    Move
  },
  props: {
    report: {
      type: Object,
      default () { return {} }
    },
    index: {
      type: Number,
      default () { return 0 }
    },
    loading: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default () { return {} }
    }
  },

  data () {
    return {
      tempLayout: []
    }
  },

  methods: {
    toggleDisplay () {
      if (this.report && this.report.defaults && this.report.defaults.display.length > 1) {
        // we have more than one display setting, let's cycle through it
        const idx = this.report.defaults.display.indexOf(this.report.display)
        let dsp = null
        if (idx < 0 || idx + 1 === this.report.defaults.display.length) {
          dsp = this.report.defaults.display[0]
        } else {
          dsp = this.report.defaults.display[idx + 1]
        }
        this.$emit('dashboardReportSetDisplay', { report: this.report.report, index: +this.index, display: dsp })
      }
    },

    saveTempLayout (val, type) {
      this.tempLayout = val

      if (type === -1) {
        this.saveLayoutChange()
      }
    },

    handleSave (bvModalEvt) {
      bvModalEvt.preventDefault()

      this.saveLayoutChange()
    },

    saveLayoutChange () {
      DashboardLayoutService.save({ layout: this.tempLayout }).then(
        (response) => {
          this.$nextTick(() => {
            this.$emit('refresh')
            this.$bvModal.hide('moveModal' + this.index)
          })
        }
      )
    }
  }
}
</script>
