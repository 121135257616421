<template>
  <ReportCard
    :report="report"
    :index="index"
    :loading="loading"
    :filters="filters"
    @dashboardReportSetDisplay="(data) => $emit('dashboardReportSetDisplay', data)"
    @refresh="$emit('refresh')"
  >
    <div
      class="chart-container"
      v-if="this.data && this.data.data && this.report.display === 'graph'"
    >
      <BarChart
        :data="formatChartData(data)"
        :options="options"
      ></BarChart>
    </div>
    <div
      v-else-if="this.data && this.data.data && this.report.display === 'table'"
      class="table-responsive"
    >
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th
              v-for="(l, idx) in data.data[0].labels"
              :key="idx"
            >
              {{ l }}
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(d, didx) in data.data"
          :key="didx"
        >
          <tr>
            <td>{{ d.label }}</td>
            <td
              v-for="(v, idx) in d.data"
              :key="idx"
            >
              <div v-if="!v.COGS && !v.Expenses">
                {{ v | currency }}
              </div>
              <div v-else>
                <div>
                  <strong>COGS: </strong> {{ v.COGS.value | currency }} <strong>{{ v.COGS.percent }}%</strong>
                </div><br>
                <div>
                  <strong>Expenses: </strong> {{ v.Expenses.value | currency }} <strong>{{ v.Expenses.percent }}%</strong>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <BarChart></BarChart>
    </div>
  </ReportCard>
</template>
<script>
import Report from '@/components/dashboard/Report'
import ReportCard from '@/components/dashboard/ReportCard'
import BarChart from '@/components/dashboard/reports/charts/Bar'

export default {
  name: 'HomeInspection',
  components: { ReportCard, BarChart },
  extends: Report,

  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      options: {
        tooltips: {
          enable: true,
          callbacks: {
            afterTitle: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem[0].datasetIndex].label
              return value
            },

            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              return this.$convertToCurrency(value)
            },

            afterLabel: (tooltipItem, data) => {
              let rev = data.datasets[0].data[0]
              if (tooltipItem.label === 'Revenue') {
                return ''
              } else {
                if (data.datasets.length === 6 && tooltipItem.datasetIndex > 3) {
                  rev = data.datasets[3].data[0]
                }
                const percent = (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / rev) * 100
                return percent.toFixed(1) + '% of Total Revenue'
              }
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                return '$' + label / 1000 + 'k'
              }
            },
            gridLines: {
              display: true
            },
            stacked: false
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            stacked: false
          }]
        },
        interaction: {
          intersect: false
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            backgroundColor: 'transparent',
            borderRadius: 4,
            color: 'black',
            font: {
              weight: 'bold'
            },
            formatter: (val) => {
              if (val === 0) {
                return ''
              }
              return this.$convertToCurrency(val)
            },
            padding: 6,
            display: 'auto',
            clamp: false,
            align: 'end',
            offset: 10,
            clip: true
          }
        }
      }
    }
  },

  methods: {

    formatChartData (data) {
      const chartData = {
        labels: data.data[0].labels,
        datasets: [
          {
            label: data.data[0].label,
            data: [data.data[0].data[0],
              0,
              0,
              0
            ],
            borderWidth: 1,
            backgroundColor: [
              this.colors[0].hoverBackgroundColor,
              this.colors[0].hoverBackgroundColor,
              this.colors[0].hoverBackgroundColor,
              this.colors[0].hoverBackgroundColor
            ],
            borderColor: [
              this.colors[0].borderColor,
              this.colors[0].borderColor,
              this.colors[0].borderColor,
              this.colors[0].borderColor
            ],
            pointBorderColor: '#2554FF'
          }
        ]
      }

      chartData.datasets.push({
        label: data.data[0].label + ' Expenses',
        data: [data.data[0].data[6],
          data.data[0].data[1].Expenses.value,
          data.data[0].data[2].Expenses.value,
          data.data[0].data[3].Expenses.value
        ],
        borderWidth: 1,
        backgroundColor: [
          this.colors[3].hoverBackgroundColor,
          this.colors[3].hoverBackgroundColor,
          this.colors[3].hoverBackgroundColor,
          this.colors[3].hoverBackgroundColor
        ],
        borderColor: [
          this.colors[3].borderColor,
          this.colors[3].borderColor,
          this.colors[3].borderColor,
          this.colors[3].borderColor
        ],
        pointBorderColor: '#2554FF'
      })

      chartData.datasets.push({
        label: data.data[0].label + ' COGS',
        data: [0,
          data.data[0].data[1].COGS.value,
          data.data[0].data[2].COGS.value,
          data.data[0].data[3].COGS.value
        ],
        borderWidth: 1,
        backgroundColor: [
          this.colors[4].hoverBackgroundColor,
          this.colors[4].hoverBackgroundColor,
          this.colors[4].hoverBackgroundColor,
          this.colors[4].hoverBackgroundColor
        ],
        borderColor: [
          this.colors[4].borderColor,
          this.colors[4].borderColor,
          this.colors[4].borderColor,
          this.colors[4].borderColor
        ],
        pointBorderColor: '#2554FF'
      })

      if (data.data.length > 1) {
        // we have comparison data
        // console.log(data.data)
        chartData.datasets.push({
          label: data.data[1].label,
          data: [data.data[1].data[0],
            0,
            0,
            0
          ],
          borderWidth: 1,
          backgroundColor: [
            this.colors[1].hoverBackgroundColor,
            this.colors[1].hoverBackgroundColor,
            this.colors[1].hoverBackgroundColor,
            this.colors[1].hoverBackgroundColor
          ],
          borderColor: [
            this.colors[1].borderColor,
            this.colors[1].borderColor,
            this.colors[1].borderColor,
            this.colors[1].borderColor
          ],
          pointBorderColor: '#2554FF'
        })

        chartData.datasets.push({
          label: data.data[1].label + ' Expenses',
          data: [0,
            data.data[1].data[1].Expenses.value,
            data.data[1].data[2].Expenses.value,
            data.data[1].data[3].Expenses.value
          ],
          borderWidth: 1,
          backgroundColor: [
            this.colors[7].hoverBackgroundColor,
            this.colors[7].hoverBackgroundColor,
            this.colors[7].hoverBackgroundColor,
            this.colors[7].hoverBackgroundColor
          ],
          borderColor: [
            this.colors[7].borderColor,
            this.colors[7].borderColor,
            this.colors[7].borderColor,
            this.colors[7].borderColor
          ],
          pointBorderColor: '#2554FF'
        })

        chartData.datasets.push({
          label: data.data[1].label + ' COGS',
          data: [0,
            data.data[1].data[1].COGS.value,
            data.data[1].data[2].COGS.value,
            data.data[1].data[3].COGS.value
          ],
          borderWidth: 1,
          backgroundColor: [
            this.colors[6].hoverBackgroundColor,
            this.colors[6].hoverBackgroundColor,
            this.colors[6].hoverBackgroundColor,
            this.colors[6].hoverBackgroundColor
          ],
          borderColor: [
            this.colors[6].borderColor,
            this.colors[6].borderColor,
            this.colors[6].borderColor,
            this.colors[6].borderColor
          ],
          pointBorderColor: '#2554FF'
        })
      }
      return chartData
    }
  }
}
</script>
