// import axios from 'axios'
import axios from 'axios'

const DashboardLayoutService = {
  defaultLayout: [
    { report: 'TotalExpense', display: 'graph' },
    { report: 'NetIncomeKPM', display: 'table' },
    { report: 'RevenueByAgent', display: 'graph' },
    { report: 'LeadGeneration', display: 'table' },
    { report: 'OperatingExpense', display: 'graph' },
    { report: 'RevenueComparison', display: 'graph' },
    { report: 'OperatingExpense', display: 'graph' }
  ],

  async getLayout (info) {
    const defaultLayout = [...this.defaultLayout]
    return new Promise((resolve, reject) => {
      axios({
        url: axios.defaults.baseURL + 'dashboard/getLayout',
        method: 'POST',
        data: JSON.stringify({ data: info })
      }).then(
        (response) => {
          if (response.data.info === null) {
            response.data.info = defaultLayout
          }
          resolve(response)
        },
        (err) => {
          reject(err)
        }
      )
    })
  },

  async getFilters () {
    return axios({
      url: axios.defaults.baseURL + 'dashboard/getFilters',
      method: 'POST'
    })
  },

  async save (info) {
    return axios({
      url: axios.defaults.baseURL + 'dashboard/saveChanges',
      method: 'POST',
      data: JSON.stringify({ data: info })
    })
  }
}

export default DashboardLayoutService
