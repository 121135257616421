<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-row class="dashboard-row" v-if="layout && widgets">
      <b-col
        v-for="(widget, index) in layout"
        :key="index"
        class="dashboard-col mb-3"
        :class="getWidgetClass(getWidgetWidth(widget))"
        :style="getWidgetHeight(widget) ? 'max-height:' + getWidgetHeight(widget) + ';min-height:' + getWidgetHeight(widget) : ''"
      >
        <container
          v-if="filters && filtersAreValid"
          :is="widget.report"
          class="dashboard-widget"
          :report="getWidgetInfo(widget)"
          :index="index"
          :filters="filters"
          @dashboardReportSetDisplay="(data) => $emit('dashboardReportSetDisplay', data)"
          @refresh="$emit('refresh')"
        ></container>
        <b-skeleton-img v-else animation no-aspect></b-skeleton-img>
      </b-col>
      <div
        v-if="!filtersAreValid"
        class="h1 invalid-filters text--white"
      >
        Please select a
        <span v-show="!this.filters || !+this.filters.company_id > 0"> company</span>
        <span v-show="!this.filters || (!+this.filters.company_id > 0 && !this.filters.startDate)"> and</span>
        <span v-show="!this.filters || !this.filters.startDate"> date range</span>
      </div>
    </b-row>
    <b-row class="dashboard-row" v-else>
      <b-col
        v-for="n in 4"
        :key="n"
        class="dashboard-col dashboard-item mb-3"
        :class="getWidgetClass(1)"
      >
        <b-skeleton-img animation no-aspect></b-skeleton-img>
      </b-col>
      <b-col
        class="dashboard-col dashboard-item mb-3"
        :class="getWidgetClass(2)"
      >
        <b-skeleton-img animation no-aspect></b-skeleton-img>
      </b-col>
      <b-col
        class="dashboard-col dashboard-item mb-3"
        :class="getWidgetClass(3)"
      >
        <b-skeleton-img animation no-aspect></b-skeleton-img>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RevenueByAgent from '@/components/dashboard/reports/RevenueByAgent'
import OperatingExpense from '@/components/dashboard/reports/OperatingExpense'
import LeadGeneration from '@/components/dashboard/reports/LeadGeneration'
import RevenueComparison from '@/components/dashboard/reports/RevenueComparison'
import TotalExpense from '@/components/dashboard/reports/TotalExpense'
import HomeInspection from '@/components/dashboard/reports/HomeInspection'

import NetIncomeKPM from '@/components/dashboard/reports/NetIncomeKPM'
import TaxableIncomeKPM from '@/components/dashboard/reports/TaxableIncomeKPM'
import HourlyRateKPM from '@/components/dashboard/reports/HourlyRateKPM'
import GrossSalesKPM from '@/components/dashboard/reports/GrossSalesKPM'
import BreakEvenKPM from '@/components/dashboard/reports/BreakEvenKPM'

export default {
  name: 'Layout',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    RevenueByAgent,
    // eslint-disable-next-line vue/no-unused-components
    OperatingExpense,
    // eslint-disable-next-line vue/no-unused-components
    LeadGeneration,
    // eslint-disable-next-line vue/no-unused-components
    RevenueComparison,
    // eslint-disable-next-line vue/no-unused-components
    TotalExpense,
    // eslint-disable-next-line vue/no-unused-components
    NetIncomeKPM,
    // eslint-disable-next-line vue/no-unused-components
    TaxableIncomeKPM,
    // eslint-disable-next-line vue/no-unused-components
    HourlyRateKPM,
    // eslint-disable-next-line vue/no-unused-components
    GrossSalesKPM,
    // eslint-disable-next-line vue/no-unused-components
    BreakEvenKPM,
    // eslint-disable-next-line vue/no-unused-components
    HomeInspection
  },

  props: {
    widgets: {
      type: Array,
      default: () => { return [] }
    },
    layout: {
      type: Array,
      default: () => { return [] }
    },
    filters: {
      type: Object,
      default: null
    }
  },

  data () {
    return {}
  },

  methods: {
    getWidgetInfo (widget) {
      const info = JSON.parse(JSON.stringify(widget))
      info.defaults = this.getWidgetDefaults(widget.report)
      return info
    },

    getWidgetDefaults (widget) {
      let info = null
      this.widgets.some((w) => {
        if (widget === w.report) {
          info = w
          return true
        }
        return false
      })
      return info
    },

    getWidgetWidth (widget) {
      if (widget.width) {
        return widget.width
      } else {
        const defaults = this.getWidgetDefaults(widget.report)
        return defaults ? defaults.width : 1
      }
    },

    getWidgetHeight (widget) {
      let height = null
      if (widget.height) {
        height = widget.height
      } else {
        const defaults = this.getWidgetDefaults(widget.report)
        if (defaults && defaults.height) {
          height = defaults.height
        }
      }
      return height
    },

    getWidgetClass (width, height) {
      if (+width === 3) {
        return 'col-12'
      } else if (+width === 2) {
        return 'col-lg-8 col-12'
      } else {
        return 'col-lg-4 col-12'
      }
    }
  },

  computed: {
    filtersAreValid: function () {
      return (this.filters && +this.filters.company_id > 0 && this.filters.startDate)
    }
  }
}
</script>
